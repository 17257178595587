.container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: var(--color-black);

    .content {
        position: absolute;
        z-index: 1;
        overflow: hidden;
        width: 100%;
        height: 100%;
        background: black;
    }


    .micromag {
        /* position: absolute;
        z-index: 1;
        top: 0;
        left: 0; */
        overflow: hidden;
        width: 100%;
        height: 100%;
        overscroll-behavior: contain;
    }

    .menuHeader,
    .menuFooter {
        padding: 0 var(--spacing);
    }

    .menuHeader {
        font-size: 1.5rem;
        text-align: center;
    }

    .menuFooter {
        margin-top: var(--block-spacing);

        .title {
            margin-bottom: var(--spacing);
        }
    }

    .menuBack {
        display: flex;
        flex-grow: 1;
        font-size: 12px;
        opacity: 0.8;
        text-decoration: none;
    }

    .menuBackLabel {
        display: inline-block;
        margin-top: 1px;
    }

    .menuBackIcon {
        margin: 0 var(--spacing-small);
        transform: rotate(180deg);
        transform-origin: center;
    }

    @media (--large-viewport) {
        .menuBack {
            display: none;
            visibility: hidden;
        }
    }
}
